import React from "react";
// import { Image } from "react-bootstrap";
// import sidebarbottom from "../../assets/images/sidebarbottom.png";

const BottomImg = () => {
  return (
    <>
      <div className="SideBottomMain d-none d-md-block">
        {/* <Image src={sidebarbottom} alt="Image description" fluid={true} /> */}
      </div>
    </>
  );
};

export default BottomImg;
