import React from "react";
import { Layout, Withdrawalncome } from "../components";

const WithdrawalIncomePage = () => {
  return (
    <>
      <Layout>
        <Withdrawalncome />
      </Layout>
    </>
  );
};

export default WithdrawalIncomePage;
