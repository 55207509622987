import React from "react";
import { Layout, DirectLegBusiness } from "../components";

const DirectLegBusinessPage = () => {
  return (
    <>
      <Layout>
        <DirectLegBusiness />
      </Layout>
    </>
  );
};

export default DirectLegBusinessPage;
