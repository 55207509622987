import React from "react";
import { Layout, ReferralIncome } from "../components";

const ReferralIncomePage = () => {
  return (
    <>
      <Layout>
        <ReferralIncome />
      </Layout>
    </>
  );
};

export default ReferralIncomePage;
