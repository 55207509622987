import React from "react";
import { Layout, LAGTokenHistory } from "../components";

const LAGTokenPageHistory = () => {
  return (
    <>
      <Layout>
        <LAGTokenHistory />
      </Layout>
    </>
  );
};

export default LAGTokenPageHistory;
