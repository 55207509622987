import React from "react";
import { Layout, LAGAirdropHistory } from "../components";
const LAGAirdropHistoryPage = () => {
  return (
    <>
      <Layout>
        <LAGAirdropHistory />
      </Layout>
    </>
  );
};

export default LAGAirdropHistoryPage;
