import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import MobileSlice from "./Slices/MobileSlice";
import NFTSlice from "./Slices/NFTSlice";

const reducer = combineReducers({
  mobile: MobileSlice,
  nft: NFTSlice,
});
export const store = configureStore({
  reducer,
});
