import nft1 from "../../assets/images/nft1.jpeg";
import nft2 from "../../assets/images/nft2.jpeg";
import nft3 from "../../assets/images/nft3.jpeg";
import nft4 from "../../assets/images/nft4.jpeg";
import nftvideo from "../../assets/images/nftvideo.mp4";
import nftvideo2 from "../../assets/images/nftvideo2.mp4";

import nft5 from "../../assets/images/nft5.jpg";
import nft6 from "../../assets/images/nft6.jpg";
import nft7 from "../../assets/images/nft7.jpg";
import nft8 from "../../assets/images/nft8.jpg";

export const nfts = [
  {
    id: 1,
    imageUrl: nft1,
    title: "Ring",
    dec: "Ring #mai",
    price: "0.40",
    count: "500",
  },
  {
    id: 2,
    imageUrl: nft2,
    title: "Ring",
    dec: "Ring #yea",
    price: "0.7",
    count: "240",
  },
  {
    id: 3,
    imageUrl: nft3,
    title: "Ring",
    dec: "Ring #GLEBA",
    price: "0.50",
    count: "700",
  },
  {
    id: 4,
    imageUrl: nft4,
    title: "Ring",
    dec: "Ring #GLEBA",
    price: "0.10",
    count: "400",
  },
  {
    id: 5,
    videoUrl: nftvideo,
    title: "Ring",
    dec: "Ring #GLEBA",
    price: "0.06",
    count: "200",
  },
];
export const CoupleNFTData = [
  {
    id: 1,
    imageUrl: nft5,
    title: "Ring",
    dec: "Ring #mai",
    price: "0.40",
    count: "500",
  },
  {
    id: 2,
    imageUrl: nft6,
    title: "Ring",
    dec: "Ring #yea",
    price: "0.7",
    count: "240",
  },
  {
    id: 3,
    imageUrl: nft7,
    title: "Ring",
    dec: "Ring #GLEBA",
    price: "0.50",
    count: "700",
  },
  {
    id: 4,
    imageUrl: nft8,
    title: "Ring",
    dec: "Ring #GLEBA",
    price: "0.10",
    count: "400",
  },
  {
    id: 5,
    videoUrl: nftvideo2,
    title: "Ring",
    dec: "Ring #GLEBA",
    price: "0.06",
    count: "200",
  },
];
